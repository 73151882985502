<template>

  <div class="panel">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">CASH REGISTER</div>
            </div>
        </div>
        <hr>
        
      <div class="form">
        <form action="" @submit.prevent="handleSubmit">


        <b-message v-if="registerPasswordExpiresIn==null" title="Cash Register Information Not Set" type="is-danger" has-icon aria-close-label="Close message">
            Please complete this form with Cash Register details to start collecting sales details.
        </b-message>

        <b-message v-else-if="registerPasswordExpiresIn<1" title="Cash Register Password Expired" type="is-danger" has-icon aria-close-label="Close message">
            <p>{{ atgRegister.registerBrand }} user {{ atgRegister.registerUser }} password expired on {{ atgRegister.registerExpirationDate }}. Sales data is not available to update since it expired.</p>
            <p>Please reset password for user <b>{{ atgRegister.registerUser }}</b> using <b>{{ atgRegister.registerBrand }} Console </b>and update this page with new password to start collecting sales data again.</p>
        </b-message>

        <b-message v-else-if="registerPasswordExpiresIn<10" title="Cash Register Password Expiring soon" type="is-warning" has-icon aria-close-label="Close message">
            <p>{{ atgRegister.registerBrand }} user {{ atgRegister.registerUser }} password will be expiring in {{ registerPasswordExpiresIn }} days (on {{ atgRegister.registerExpirationDate }}).</p>
            <p>Please reset password for user <b>{{ atgRegister.registerUser }}</b> using <b>{{ atgRegister.registerBrand }} Console </b>and update this page with new password to avoid interruption to collecting sales data.</p>
        </b-message>

        <b-message v-else-if="registerPasswordExpiresIn>9" title="Cash Register Details Set" type="is-success" has-icon aria-close-label="Close message">
            <p>{{ atgRegister.registerBrand }} user {{ atgRegister.registerUser }} password will be expiring in {{ registerPasswordExpiresIn }} days (on {{ atgRegister.registerExpirationDate }}).</p>
        </b-message>

        <div class="columns">

            <div class="column">
                <b-field label="Register Brand/Model" label-for="atgRegister.registerBrand">

                    <b-select 
                        v-model="atgRegister.registerBrand" 
                        placeholder="Select Register Brand/Model" 
                        required>
                        <option value="Verifone Commander" selected>Verifone Commander</option>
                    </b-select>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-field label="POS IP Address"  label-for="atgRegister.registerIP">
                    <b-input type="text"
                        v-model="atgRegister.registerIP" 
                        icon="briefcase"
                        maxlength="20"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
        </div>        

        <div class="columns">
            <div class="column">
                <b-field label="User Name" label-for="atgRegister.registerUser">
                    <b-input type="text"
                        v-model="atgRegister.registerUser" 
                        icon="briefcase"
                        maxlength="40"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">

            <div class="column">

                <b-field label="Password" label-for="atgRegister.registerPass">
                    <b-input type="password"
                        v-model="atgRegister.registerPass" 
                        icon="briefcase"
                        maxlength="20"
                        required
                        password-reveal                   
                        >
                    </b-input>
                </b-field>

            </div>
        </div>

        <div class="columns">

        <div class="column">

            <b-field label="ATG IP Address" label-for="atgIP">
                <b-input type="text"
                    v-model="atgRegister.atgIP" 
                    icon="briefcase"
                    maxlength="20"
                    required
                    >
                </b-input>
            </b-field>

        </div>
        </div>        

        <div>
            <button type="submit" class="button is-primary" :disabled='!formComplete'>Save</button>
        </div> 
        </form>
<!-- 
        <div>
            {{ atgRegister }}
        </div> 
        <div>
            {{ atgRegisterCurrent }}
        </div>          -->
        
      </div>

        <div v-if="isLoading">
            <data-loading 
                :isLoading="isLoading" 
                :isFullPage="isFullPage"> 
            </data-loading>
        </div>

  </div>

    
</template>


<script>


import UserStore from "../../../../store/UserStore"
import axios from 'axios'
import router from "../../../../router"
import DataLoading from '../../../../components/app/DataLoading.vue'

export default {

    components: {

        DataLoading

    },

    metaInfo: {
        title: 'Update Cash Register Details'
    },

    data() {
        return {
            today: new Date(),
            isLoading: false,
            isFullPage: true,
            atgRegisterCurrent: {},
            atgRegister: {},

        }
    },

    methods: {
        fetchData() {

            this.isLoading = true

			if (  UserStore.getters.getActiveStore.storeId ) {
				axios.get('/sapi/stores/' + UserStore.getters.getActiveStore.storeId + "/atg_register", {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.atgRegister = response.data.data
                    this.atgRegisterCurrent = Object.assign({}, response.data.data)

				})
				.catch( (err) => {
                    this.isLoading = false
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
            this.isLoading = false            

        },

        handleSubmit() {
			if (  UserStore.getters.getActiveStore.storeId ) {
                this.isLoading = true

                if (this.formComplete) {
                    
                    const formatYmd = date => date.toISOString().slice(0, 10);

                    var newExpire = new Date()

                    var expire = formatYmd(new Date(newExpire.setTime( newExpire.getTime() + 30 * 86400000 )))

                    if (this.atgRegister.registerPort == null) {
                        this.atgRegister.registerPort = "443"
                    }
                    axios.post('/sapi/stores/' + UserStore.getters.getActiveStore.storeId + "/atg_register", {
                            "atgIP": this.atgRegister.atgIP,
                            "atgPort": this.atgRegister.atgPort,
                            "atgBrand": this.atgRegister.atgBrand,
                            "registerBrand": this.atgRegister.registerBrand,
                            "registerIP": this.atgRegister.registerIP,
                            "registerPort": this.atgRegister.registerPort,
                            "registerUser": this.atgRegister.registerUser,
                            "registerPass": this.atgRegister.registerPass,
                            "deviceId": this.atgRegister.deviceId,
                            "deviceOsVersion": this.atgRegister.deviceOsVersion,
                            "registerMasterUser": this.atgRegister.registerMasterUser,
                            "registerMasterPass": this.atgRegister.registerMasterPass,
                            "registerOldUser": this.atgRegister.registerOldUser,
                            "registerOldPass": this.atgRegister.registerOldPass,
                            "active": this.atgRegister.active,
                            "registerExpirationDate": expire,
                        },
                        {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken					},				
                        })
                    .then( response => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        if (response.status === 200) {
                            UserStore.commit('fetchStoreSettings', UserStore.getters.getActiveStore.storeId)
                            this.postSuccess = true
                            this.$buefy.notification.open({
                                    message: 'Store Cash Register Information Updated',
                                    type: 'is-success',
                                    duration: 1000,
                                    'has-icon': true,
                                    'icon-pack': 'fas',
                                    icon: 'exclamation-circle'
                            })
                            router.push({ path: '/' })
                        }
                    })

                }
                this.isLoading = false
            }

        },
    },

    computed: {
        formComplete() {

            if (
                this.atgRegister.registerBrand &&
                this.atgRegister.registerIP &&
                this.atgRegister.registerUser &&
                this.atgRegister.registerPass && 
                ( 
                    this.atgRegisterCurrent.registerBrand != this.atgRegister.registerBrand ||
                    this.atgRegisterCurrent.registerIP != this.atgRegister.registerIP ||
                    this.atgRegisterCurrent.registerUser != this.atgRegister.registerUser ||
                    this.atgRegisterCurrent.registerPass != this.atgRegister.registerPass
                )
               ) 
            {
                return true
            } else {
                 return false
            }
           
        },

        registerPasswordExpiresIn() {
            return UserStore.getters.getStoreRegisterPasswordExpireIn
        },        

        registerPasswordExpiresIn2() {


            // var expireDate = new Date()

            if (this.atgRegister.registerExpirationDate != null) {

                var expireDate = new Date(this.atgRegister.registerExpirationDate)
            } else {
                return null
            }

            var timeDiff = expireDate.getTime() - this.today.getTime(); 
            var expireIn = Math.ceil(timeDiff / (1000 * 3600 * 24));

            return expireIn

        },

    },

    mounted() {
        
        this.fetchData()
    }


}
</script>



	